<template>
  <div class="switch">
    <div
      class="switchItem"
      :style="Boolean(value) ? mstyle : ''"
      @click="disabled?null:chanvalue()"
    >
      <span :class="Boolean(value) ? 'block move' : 'block'"></span>
    </div>
    <label @click="disabled?null:chanvalue()">
      <slot></slot>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default() {
        return 0;
      },
    },
    mstyle: {
      type: String,
      default() {
        return "";
      },
    },
    disabled: {
      type: Boolean,
      default:false
    },
  },
  watch:{
      value(){
        this.$emit("changevalue");
      }
  },
  methods: {
    chanvalue() {
      this.$emit("input",Number(!Boolean(this.value)));
      this.$emit("click",Number(!Boolean(this.value)));
    },
  },
};
</script>
<style lang="scss" scoped>
.switch {
  position: relative;
  height: 100%;
  .switchItem {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    height: 23px;
    width: 40px;
    padding: 2px;
    border-radius: 3px;
    background: #cbd0de;
    .block {
      display: inline-block;
      height: 100%;
      width: 50%;
      background: #fff;
      border-radius: 3px;
      transform: translateX(0);
      transition: 0.3s;
    }
    .move {
      transform: translateX(100%);
    }
  }
  label {
    position: absolute;
    left: 50px;
    top: 50%;
    white-space: nowrap;
    transform: translateY(-50%);
  }
}
</style>