<template>
  <div class="mchebox">
    <div class="mcheboxl">
      <span
        @click="disabled?'':clicklable()"
        :class="Boolean(value) ? ['border_ic', 'ischecked'] : 'border_ic'"
      >
        <i v-if="value" class="iconfont icon-xuanzhong"></i>
      </span>
      <label for="checkid" @click="disabled?'':clicklable()">
        <slot></slot>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default() {
        return 0;
      },
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  methods: {
    clicklable(){
      this.$emit('input',Number(!Boolean(this.value)))
      this.$emit("click",Number(!Boolean(this.value)))
    }
  },
};
</script>
<style lang="scss" scoped>
.mchebox {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  .mcheboxl {
    position: relative;
    height: 100%;
    width: 100%;
    display: inline-block;
    label {
      margin-left: 20px;
    }
    .border_ic {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 13px;
      height: 13px;
      border: 1px solid #e8e8e8;
      background: #cbd0de;
      border-radius: 3px;
    }
    .ischecked {
      border-color: #2b56bf;
      background: #2b56bf;
    }
    .icon-xuanzhong {
      position: absolute;
      left: 50%;
      top: 50%;
      color: #fff;
      font-size: 80%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>