<template>
  <div class="rolejob">
    <div class="rolejob_content">
      <div class="table_left">
        <div class="southcargo_bot_title">
          <span>角色名称</span>
        </div>
        <ul class="content_box">
          <template v-for="item in tablelist">
            <li
              class="list_item"
              :class="
                item.roleId && checkeditem === item.roleId ? 'active' : ''
              "
              :key="item.roleId"
              @click="checkfun(item.roleId)"
            >
              <span
                class="item_bar"
                v-if="item.roleId && checkeditem === item.roleId"
              ></span>
              {{ item.roleName }}
            </li>
          </template>
        </ul>
      </div>
      <div class="table_right">
        <div class="southcargo_bot_title">
          <span>权限分配</span>
        </div>
        <div class="content_box">
          <template v-for="leve in rolelist">
            <div class="topbox" :key="leve.id">
              <m-switch
                v-model="leve.check"
                mstyle="background: #13D168;"
                :disabled="true"
                >{{ leve.name }}</m-switch
              >
            </div>
            <template v-for="secondary in leve.child">
              <div class="lineown" :key="secondary.id">
                <div class="towItem">
                  <m-switch
                    v-model="secondary.check"
                    :disabled="true"
                    mstyle="background: #2B56BF;"
                    @changevalue="funbtn"
                    >{{ secondary.name }}</m-switch
                  >
                </div>
                <template v-for="three in secondary.child">
                  <div @click="checkRole" class="item" :key="three.id">
                    <m-chebox
                      :disabled="checkeditem ? false : true"
                      @input="funbtn"
                      v-model="three.check"
                    >
                      <span>{{ three.name }}</span>
                    </m-chebox>
                  </div>
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mTitle from "@/components/mTitle.vue";
import mChebox from "@/components/mChebox.vue";
import mSwitch from "@/components/mSwitch.vue";
export default {
  data() {
    return {
      listitem: [{ name: "角色权限" }, { name: "权限分配" }],
      checkeditem: null,
      tablelist: [],
      rolelist: [],
      selectItem: [],
      timeout: null,
      itemclick: null,
    };
  },
  watch: {
    selectItem(nval) {
      if (!nval.length) return;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async (_) => {
        if (this.checkeditem) {
          await this.$http("api1", "/sys/role/addOrUpdate", "post", {
            roleId: this.checkeditem,
            menuIdList: nval,
          });
        }
      }, 30);
    },
  },
  components: {
    mTitle,
    mChebox,
    mSwitch,
  },
  created() {
    this.rolejobList();
  },
  methods: {
    async rolejobList() {
      const { data } = await this.$http("api1", "/sys/role/list", "get");
      if (!data || data.msg != "success")
        return this.$message.error("获取数据失败");
      this.tablelist = data.page.list;
      const { data: role } = await this.$http(
        "api1",
        "/sys/menu/getAllMenu",
        "post"
      );
      this.rolelist = role.data;
    },
    async checkfun(index) {
      if (!index || this.checkeditem == index) return;
      this.checkeditem = index;
      const { data } = await this.$http(
        "api1",
        `/sys/role/info/${index}`,
        "get"
      );
      if (!data || data.msg != "success")
        return this.$message.error("获取数据失败");
      this.selectItem = [...data.role.menuIdList];
      this.recursive(this.rolelist, this.selectItem);
    },
    checkRole() {
      if (!this.checkeditem) {
        this.$message.error("请先选择左边的角色后再操作！");
        return;
      }
    },
    funbtn() {
      this.btnrecursive(this.rolelist, this.selectItem);
    },
    // 当点击角色的时候递归渲染
    recursive(list, table) {
      list.forEach((item) => {
        if (table.includes(item.id)) {
          item.check = 1;
        } else {
          item.check = 0;
        }
        if (item.child) {
          this.recursive(item.child, table);
        }
      });
      return "";
    },
    // 当点击按钮的时候递归渲染
    btnrecursive(list, table) {
      list.forEach((item) => {
        if (item.child && item.child instanceof Array) {
          var istrue = item.child.some((val) => val.check == 1);
          item.check = Number(istrue);
          if (!item.check && table.includes(item.id)) {
            var findx = table.indexOf(item.id);
            table.splice(findx, 1);
          } else if (item.check && !table.includes(item.id)) {
            table.push(item.id);
          }
          this.btnrecursive(item.child, table);
          return;
        } else {
          if (!item.check && table.includes(item.id)) {
            var index = table.indexOf(item.id);
            table.splice(index, 1);
          } else if (item.check && !table.includes(item.id)) {
            table.push(item.id);
          }
          return;
        }
      });
      return;
    },
  },
};
</script>
<style lang="scss" scoped>
.rolejob {
  position: relative;
  height: 100%;
  .rolejob_content {
    position: absolute;
    top: 30px;
    bottom: 0;
    width: 100%;
    background: #f0f0f0;
    .table_left {
      float: left;
      position: relative;
      width: 14%;
      height: 100%;
      background: #fff;
      border-radius: 2px;
    }
    .table_right {
      float: right;
      position: relative;
      width: 85%;
      height: 100%;
      background: #fff;
      margin-left: 1%;
      border-radius: 2px;
    }
    .southcargo_bot_title {
      box-sizing: border-box;
      height: 45px;
      line-height: 45px;
      font-weight: bold;
      font-size: 100%;
      padding-left: 10px;
    }
    .content_box {
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      top: 45px;
      bottom: 0;
      overflow: auto;
      cursor: pointer;
      border-top: 1px solid #f3f4f5;
      .topbox {
        box-sizing: border-box;
        font-weight: 600;
        padding: 0 10px;
        height: 6%;
      }
      .lineown {
        box-sizing: border-box;
        margin: 0 30px;
        padding: 20px 0;
        height: 8%;
        background: #f9f9f9;
        .towItem {
          display: inline-block;
          width: 15%;
          vertical-align: middle;
        }
        .item {
          display: inline-block;

          width: 10%;
        }
        .item:first-child {
          font-weight: 500;
          margin-left: 5%;
          width: 12%;
        }
      }
    }
    ul {
      margin: 0;
      list-style: none;
      padding: 0;
      .list_item {
        position: relative;
        box-sizing: border-box;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
        .item_bar {
          position: absolute;
          left: 0;
          height: 100%;
          width: 5px;
          background: #2e39ce;
        }
        &.active {
          background-color: #c9cdff;
        }
      }
    }
  }
}
</style>
